import React, { useState, useEffect } from "react"

import Layout from "components/layout"
import { useQuery, useLazyQuery } from "@apollo/client"
import { Button } from "components/anti/buttons/buttons"
import { Modal } from "components/anti/modal/modal"
import { parseCookies } from "nookies"
import Seo from "components/seo"

import {
  getTestPCRQuery,
  testPCRDetailQuery,
} from "components/utils/streamworx"

const Covid19TestRiwayat = () => {
  const authTokenNew = parseCookies().authToken
    ? `Bearer ${parseCookies().authToken}`
    : null
  const streamworx_config = {
    context: {
      clientName: "streamworx",
      headers: { authorization: authTokenNew },
    },
  }

  const { data: getTestPCR } = useQuery(getTestPCRQuery, streamworx_config)
  const [gettestPCRDetail, { data: testPCRDetail }] = useLazyQuery(
    testPCRDetailQuery,
    streamworx_config
  )

  const [selectedPCR, setSelectedPCR] = useState()
  const [showModal, setShowModal] = useState("")

  useEffect(() => {
    ;(async () => {
      const testPCR = await getTestPCR
      if (testPCR?.getTestPCR) {
        const testId = testPCR?.getTestPCR[0]?.testId
        setSelectedPCR(testPCR?.getTestPCR[0])
        gettestPCRDetail({
          variables: { testId },
        })
      }
    })()
  }, [getTestPCR])

  const changeSelected = pcr => {
    setSelectedPCR(pcr)
    const testId = pcr.testId
    gettestPCRDetail({
      variables: { testId },
    })
  }

  const mobileOpen = pcr => {
    changeSelected(pcr)
    setShowModal("result")
  }

  return (
    <Layout>
      <Seo title="Riwayat Covid-19 Test" />
      <section className="py-main sc-covid19Test-riwayat">
        <div className="container">
          <Button
            variant="link"
            className="btn-back-help ai-arrow-from-left mb-3"
            link={"/covid-19-test"}
          >
            Kembali
          </Button>
          <h2 className="mb-4">Riwayat COVID-19 Test</h2>
          {getTestPCR?.getTestPCR?.length <= 0 && (
            <div className="w-100">
              <p className="text-center text-muted">
                Anda belum pernah melakukan transaksi
              </p>
            </div>
          )}
          <div className="row row-4">
            <div className="col-md-4">
              <ul className="riwayat-list">
                {getTestPCR &&
                  getTestPCR?.getTestPCR?.map((pcr, i) => {
                    const status = pcr.testStatus
                    let classStatus = ""
                    if (status === "SELESAI") {
                      classStatus = "SUCCESS"
                    } else if (status === "Failed Payment") {
                      classStatus = "FAILED"
                    } else if (status === "Expired Payment") {
                      classStatus = "FAILED"
                    } else if (status === "Payment Cancelled") {
                      classStatus = "CANCELLED"
                    } else {
                      classStatus = "PENDING"
                    }
                    return (
                      <React.Fragment key={i}>
                        <li
                          className={`btn list-content p-2 d-md-block d-none ${pcr?.testId ===
                            selectedPCR?.testId && "active"}`}
                          onClick={() => changeSelected(pcr)}
                        >
                          <div className="list-card">
                            <p className="mb-1">{pcr?.testDate}</p>
                            <h5 className="mb-2">{pcr?.testName}</h5>
                            <p>
                              <span
                                className={`border-status p-1 text-uppercase font-weight-bold ${classStatus}`}
                              >
                                {pcr?.testStatus}
                              </span>
                            </p>
                          </div>
                        </li>
                        <li
                          className={`btn list-content p-2 d-block d-md-none ${pcr?.testId ===
                            selectedPCR?.testId && "active"}`}
                          onClick={() => mobileOpen(pcr)}
                        >
                          <div className="list-card">
                            <p className="mb-1">{pcr?.testDate}</p>
                            <h5 className="mb-2">{pcr?.testName}</h5>
                            <p>
                              <span
                                className={`border-status p-1 text-uppercase font-weight-bold ${classStatus}`}
                              >
                                {pcr?.testStatus}
                              </span>
                            </p>
                          </div>
                        </li>
                      </React.Fragment>
                    )
                  })}
              </ul>
            </div>
            {selectedPCR && (
              <>
                <div className="col-md-8 d-md-block d-none">
                  <p className="caption mb-1 bg-light py-2 px-3">
                    Detail Pembelian
                  </p>
                  <div className="p-3">
                    <div className="produk">
                      <p className="caption mb-1">Produk yang Dipilih</p>
                      {testPCRDetail &&
                        testPCRDetail.testPCRDetail.testItem.map((item, i) => {
                          return (
                            <div
                              className="d-flex justify-content-between align-items-center"
                              key={i}
                            >
                              <p className="mb-2">{item.name}</p>
                              {/* <Button variant="link" className="pt-0">
                                                        Lihat Hasil Tes
                                                    </Button> */}
                            </div>
                          )
                        })}
                      {testPCRDetail &&
                        testPCRDetail?.testPCRDetail?.testResultDocs?.length >
                          0 && (
                          <Button
                            variant="link"
                            className="pt-0"
                            onClick={() => setShowModal("hasilTes")}
                          >
                            Lihat Hasil Tes
                          </Button>
                        )}
                    </div>
                    <div className="tanggal">
                      <p className="caption mb-1">Tanggal Tes</p>
                      <p>
                        {selectedPCR.testDate} • {selectedPCR.testTime}
                      </p>
                    </div>
                    <div className="status">
                      <p className="caption mb-1">Status</p>
                      <p>{selectedPCR.testStatus}</p>
                    </div>
                  </div>
                  <p className="caption mb-1 bg-light py-2 px-3">
                    Informasi Kontak
                  </p>
                  <div className="p-3">
                    <div className="nomor-wa">
                      <p className="caption mb-1">Nomor WhatsApp</p>
                      <p>{selectedPCR.waPhoneNumber}</p>
                    </div>
                    <div className="email">
                      <p className="caption mb-1">E-mail</p>
                      <p>{selectedPCR.email}</p>
                    </div>
                  </div>
                  <p className="caption mb-1 bg-light py-2 px-3">Lokasi tes</p>
                  <div className="p-3">
                    <div className="provinsi">
                      <p className="caption mb-1">Provinsi</p>
                      <p>{selectedPCR.province}</p>
                    </div>
                    <div className="kota">
                      <p className="caption mb-1">Kota</p>
                      <p>{selectedPCR.city}</p>
                    </div>
                    <div className="alamat">
                      <p className="caption mb-1">Alamat</p>
                      <p>{selectedPCR.address}</p>
                    </div>
                  </div>
                </div>
                <div className="d-block d-md-none">
                  <Modal
                    id="result"
                    title="Riwayat BPJS Kesehatan"
                    // img={bank_bri}
                    isShowing={showModal}
                    // hide={closeModal}
                    className="modal-md bayar-tagihan-modal sc-bpjs-kategori"
                  >
                    <div className="bayar-tagihan-modal-content animated fadeInDown delayp5 overflow-auto h-500px">
                      <p className="caption mb-1 bg-light py-2 px-3">
                        Detail Pembelian
                      </p>
                      <div className="p-3">
                        <div className="produk">
                          <p className="caption mb-1">Produk yang Dipilih</p>
                          {testPCRDetail &&
                            testPCRDetail.testPCRDetail.testItem.map(
                              (item, i) => {
                                return (
                                  <div
                                    className="d-flex justify-content-between align-items-center"
                                    key={i}
                                  >
                                    <p>{item.name}</p>
                                    {/* <Button variant="link" className="pt-0">
                                                        Lihat Hasil Tes
                                                    </Button> */}
                                  </div>
                                )
                              }
                            )}
                          <Button
                            variant="link"
                            className="pt-0"
                            onClick={() => setShowModal("hasilTes")}
                          >
                            Lihat Hasil Tes
                          </Button>
                        </div>
                        <div className="tanggal">
                          <p className="caption mb-1">Tanggal Tes</p>
                          <p>
                            {selectedPCR.testDate} • {selectedPCR.testTime}
                          </p>
                        </div>
                        <div className="status">
                          <p className="caption mb-1">Status</p>
                          <p>{selectedPCR.testStatus}</p>
                        </div>
                      </div>
                      <p className="caption mb-1 bg-light py-2 px-3">
                        Informasi Kontak
                      </p>
                      <div className="p-3">
                        <div className="nomor-wa">
                          <p className="caption mb-1">Nomor WhatsApp</p>
                          <p>{selectedPCR.waPhoneNumber}</p>
                        </div>
                        <div className="email">
                          <p className="caption mb-1">E-mail</p>
                          <p>{selectedPCR.email}</p>
                        </div>
                      </div>
                      <p className="caption mb-1 bg-light py-2 px-3">
                        Lokasi tes
                      </p>
                      <div className="p-3">
                        <div className="provinsi">
                          <p className="caption mb-1">Provinsi</p>
                          <p>{selectedPCR.province}</p>
                        </div>
                        <div className="kota">
                          <p className="caption mb-1">Kota</p>
                          <p>{selectedPCR.city}</p>
                        </div>
                        <div className="alamat">
                          <p className="caption mb-1">Alamat</p>
                          <p>{selectedPCR.address}</p>
                        </div>
                      </div>
                    </div>
                    <Button
                      variant="primary"
                      className="m-4"
                      onClick={() => setShowModal("")}
                    >
                      Tutup
                    </Button>
                  </Modal>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <Modal
        id="hasilTes"
        // img={bank_bri}
        isShowing={showModal}
        hide={() => setShowModal("")}
        className="modal-md bayar-tagihan-modal sc-bpjs-kategori"
      >
        <div className="container">
          <div className="d-flex align-items-center">
            {testPCRDetail &&
              testPCRDetail?.testPCRDetail?.testResultDocs.map((item, i) => {
                return (
                  <Button
                    variant="link"
                    key={i}
                    link={item.documentUrl}
                    className="mr-3"
                  >
                    {item.documentName}
                  </Button>
                )
              })}
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default Covid19TestRiwayat
